import React from "react"

import Layout from "../components/layout"
import { BodyText } from "../components/bodyText"

const NotFoundPage = () => (
  <Layout meta={{ pageTitle: "404 Not Found" }}>
    <BodyText
      article={{ pageTitle: "Sorry, We can't find this page" }}
      align="left"
    />
  </Layout>
)

export default NotFoundPage

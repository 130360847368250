import React, { Fragment } from "react"
import "../assets/css/bodyText.scss"
import CTA from "../components/cta"

export const BodyText = ({
  article,
  align,
  secondary = false,
  className,
  landing = false,
}) => {
  const title = article.pageTitle || article.name
  return (
    <section
      className={`body ${secondary ? "mt-n" : ""} ${
        className ? className : ""
      }`}
    >
      <div className={`talign-center title ${secondary ? "mb-n" : ""}`}>
        {!secondary ? (
          <Fragment>
            <h1 className={title.toLowerCase().replace("the ", "")[0]}>
              {title}
            </h1>
            <div className="divider"></div>
            {landing && (
              <div class="sm-display-block display-none mt-xl">
                <CTA data={article.hero.cta} color="light" />
              </div>
            )}
          </Fragment>
        ) : (
          <h2>{title}</h2>
        )}
        {article.subTitle && <h3>{article.subTitle}</h3>}
      </div>
      {article.text && (
        <div
          className={`body-text talign-${align ? align : "center"}`}
          dangerouslySetInnerHTML={{ __html: article.text }}
        ></div>
      )}
    </section>
  )
}
